import {
  default as Billing,
  default as Invoices,
} from '../../../../images/icons/billing.png';
import Home from '../../../../images/icons/dashboard.png';
import Issues from '../../../../images/icons/issue.png';
import MessageCenter from '../../../../images/icons/message.png';
import Property from '../../../../images/icons/property.png';
import PropertyTypes from '../../../../images/icons/property_types.png';
import Reservations from '../../../../images/icons/reservations.png';
import Security from '../../../../images/icons/security.png';
import Settings from '../../../../images/icons/settings.png';
import User from '../../../../images/icons/user.png';

const Navigation = [
  {
    name: 'Home',
    url: Home,
    alt: 'dashboard',
    link: '/dashboard',
    activeRoute: ['/dashboard'],
  },
  {
    name: 'Property Management',
    url: Property,
    alt: 'Property Management',
    link: '/property-management',
    activeRoute: ['/property-management', '/property-management/unit-types'],
  },
  {
    name: 'Property Types',
    url: PropertyTypes,
    alt: 'Property Types',
    link: '/property-types',
    activeRoute: ['/property-types'],
  },
  {
    name: 'User Management',
    url: User,
    alt: 'user',
    link: '/user-management/tenant',
    activeRoute: [
      '/user-management/tenant',
      '/user-management/guards',
      '/user-management/admin',
    ],
  },
  {
    name: 'Billing',
    url: Billing,
    alt: 'billing',
    link: '/billing/unpaid',
    activeRoute: ['/billing/unpaid', '/billing/paid'],
  },
  {
    name: 'Security Approvals',
    url: Security,
    alt: 'security',
    link: '/security/pending',
    activeRoute: [
      '/security/pending',
      '/security/rejected',
      '/security/approved',
    ],
  },
  {
    name: 'Reservations',
    url: Reservations,
    alt: 'reservations',
    link: '/reservations/facilities',
    activeRoute: [
      '/reservations/facilities',
      '/reservations/reservations',
      '/reservations/services',
    ],
  },
  {
    name: 'Message Center',
    url: MessageCenter,
    alt: 'Message Center',
    link: '/message-center',
    activeRoute: ['/message-center', '/message-center/channels'],
  },
  {
    name: 'Issues',
    url: Issues,
    alt: 'issues',
    link: '/issues',
    activeRoute: ['/issues'],
  },
  {
    name: 'Settings',
    url: Settings,
    alt: 'settings',
    link: '/settings',
    activeRoute: [
      '/settings',
      '/settings/edit-profile',
      '/settings/manage-bank',
    ],
  },
  {
    name: 'Invoices',
    url: Invoices,
    alt: 'invoices',
    link: '/invoices',
    activeRoute: ['/invoices'],
  },
];

export default Navigation;
