/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { lazy } from 'react';
import { Cookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import {
  // BrowserRouter,
  Redirect,
  Route,
  Router,
  Switch,
} from 'react-router-dom';
import history from 'utils/history';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStyle } from 'styles/global-styles';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import theme from '../styles/theme-css';
import { LoadingScreen } from './components/Loading';
import { commonSaga } from './containers/Common/saga';
import { selectCommon } from './containers/Common/selectors';
import {
  actions as CommonAction,
  reducer as CommonReducer,
  sliceKey as CommonSliceKey,
} from './containers/Common/slice';
// @todo: refactor container imports
// tranfer to a router handler (e.g. pmo-ux)
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { AdminRegistration } from './containers/AdminRegistration/Loadable';
import { BillingAddTransaction } from './containers/BillingAddTransaction/Loadable';
import { BillingPaid } from './containers/BillingPaid/Loadable';
import { BillingUnpaid } from './containers/BillingUnpaid/Loadable';
import { BookADemo } from './containers/BookADemo/Loadable';
import { Dashboard } from './containers/Dashboard/Loadable';
import { ForgotPassword } from './containers/ForgotPassword/Loadable';
import { Invoices } from './containers/Invoices/Loadable';
import { Issues } from './containers/Issues/Loadable';
import { LandingPage } from './containers/LandingPage/Loadable';
import { Login } from './containers/Login/Loadable';
import { ManageBankAccounts } from './containers/ManageBankAccounts/Loadable';
import { ManageProperty } from './containers/ManageProperty/Loadable';
import { MessageCenter } from './containers/MessageCenter/Loadable';
import { MessageCenterChannels } from './containers/MessageCenterChannels/Loadable';
import { PaymentConfirmation } from './containers/PaymentConfirmation/Loadable';
import { ProductFacilities } from './containers/ProductFacilities/Loadable';
import { ProductParking } from './containers/ProductParking/Loadable';
import { ProductService } from './containers/ProductService/Loadable';
import { PromoCodes } from './containers/PromoCodes/Loadable';
import { ReservationsFacilities } from './containers/ReservationsFacilities/Loadable';
import { ReservationsParking } from './containers/ReservationsParking/Loadable';
import { ReservationsServices } from './containers/ReservationsServices/Loadable';
import { SecurityApprovals } from './containers/SecurityApprovals/Loadable';
import { SecurityApprovalsApproved } from './containers/SecurityApprovalsApproved/Loadable';
import { SecurityApprovalsRejected } from './containers/SecurityApprovalsRejected/Loadable';
import { Settings } from './containers/Settings/Loadable';
import { SignUp } from './containers/SignUp/Loadable';
import { AlertProvider, AppNavProvider } from './providers';

const MarketingModule = lazy(() => import('./modules/marketing'));
const UserManagementModule = lazy(() => import('./modules/user-management'));
const PropertyManagementModule = lazy(() =>
  import('./modules/property-management'),
);
const PropertyTypesModule = lazy(() => import('./modules/property-types'));

export function App() {
  useInjectSaga({ key: CommonSliceKey, saga: commonSaga });
  useInjectReducer({ key: CommonSliceKey, reducer: CommonReducer });
  const dispatch = useDispatch();
  const common = useSelector(selectCommon);
  const { auth, loading, profile } = common;
  const handleLogout = () => {
    dispatch(CommonAction.signOutAction());
  };
  const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        auth &&
        (window.location.pathname === '/' ||
          window.location.pathname === '/login' ||
          window.location.pathname === '/forgot-password' ||
          window.location.pathname === '/register' ||
          window.location.pathname.includes('/register/admin/')) ? (
          <Redirect
            to={{
              pathname: '/manage-property',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );

  interface PrivateRouteProps {
    component?: any;
    children?: React.ReactNode;
    path: string;
    exact?: boolean;
    menuClick?: () => void;
  }

  const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, children, ...rest } = props;
    const cookies = new Cookies();
    const userProfile = cookies.get('user_profile');
    if (!userProfile) {
      return (
        <Route
          {...rest}
          render={props => (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )}
        />
      );
    }
    let hasProperty: boolean = true;
    if (
      userProfile.account instanceof Array &&
      userProfile.account.length === 0
    ) {
      hasProperty = false;
    }

    if ((auth || userProfile) && hasProperty) {
      return <Route {...rest}>{Component ? <Component /> : children}</Route>;
    }

    if (auth && userProfile && !hasProperty) {
      if (window.location.pathname === '/manage-property') {
        return <Route {...rest}>{Component ? <Component /> : children}</Route>;
      }

      return (
        <Route
          {...rest}
          render={props => (
            <Redirect
              to={{
                pathname: '/manage-property',
                state: { from: props.location },
              }}
            />
          )}
        />
      );
    }

    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  };

  React.useEffect(() => {
    dispatch(CommonAction.checkAuthAction());
  }, [auth, dispatch]);

  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <AlertProvider>
          {loading && <LoadingScreen variant="dark" />}
          <Helmet titleTemplate="%s - Kubo Admin" defaultTitle="Kubo Admin">
            <meta name="description" content="Kubo Admin Application" />
          </Helmet>
          <Switch>
            <UnauthenticatedRoute exact path="/" component={LandingPage} />
            <Route path="/kubo">
              <React.Suspense fallback={<LoadingScreen variant="light" />}>
                <MarketingModule />
              </React.Suspense>
            </Route>
            <UnauthenticatedRoute exact path="/login" component={Login} />
            <UnauthenticatedRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <UnauthenticatedRoute exact path="/register" component={SignUp} />
            <UnauthenticatedRoute exact path="/demo" component={BookADemo} />
            <UnauthenticatedRoute
              exact
              path="/register/admin/:token"
              component={AdminRegistration}
            />
            <PrivateRoute
              exact
              path="/manage-property"
              component={ManageProperty}
            />
            <AppNavProvider userProfile={profile} logout={handleLogout}>
              <PrivateRoute
                exact
                path="/billing/add-transaction"
                component={BillingAddTransaction}
              />
              <PrivateRoute
                exact
                path="/billing/paid"
                component={BillingPaid}
              />
              <PrivateRoute
                exact
                path="/billing/unpaid"
                component={BillingUnpaid}
              />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/issues" component={Issues} />
              <PrivateRoute
                exact
                path="/message-center"
                component={MessageCenter}
              />
              <PrivateRoute
                exact
                path="/message-center/channels"
                component={MessageCenterChannels}
              />
              <PrivateRoute
                exact
                path="/reservations/facility-list"
                component={ProductFacilities}
              />
              <PrivateRoute
                exact
                path="/reservations/parking-list"
                component={ProductParking}
              />
              <PrivateRoute
                exact
                path="/reservations/services-list"
                component={ProductService}
              />
              <PrivateRoute
                exact
                path="/reservations/promo-codes"
                component={PromoCodes}
              />
              <PrivateRoute path="/property-management">
                <React.Suspense fallback={<LoadingScreen variant="light" />}>
                  <PropertyManagementModule />
                </React.Suspense>
              </PrivateRoute>
              <PrivateRoute path="/property-types">
                <React.Suspense fallback={<LoadingScreen variant="light" />}>
                  <PropertyTypesModule />
                </React.Suspense>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/reservations/facilities"
                component={ReservationsFacilities}
              />
              <PrivateRoute
                exact
                path="/reservations/parking"
                component={ReservationsParking}
              />
              <PrivateRoute
                exact
                path="/reservations/services"
                component={ReservationsServices}
              />
              <PrivateRoute
                exact
                path="/security/pending"
                component={SecurityApprovals}
              />
              <PrivateRoute
                exact
                path="/security/approved"
                component={SecurityApprovalsApproved}
              />
              <PrivateRoute
                exact
                path="/security/rejected"
                component={SecurityApprovalsRejected}
              />
              <PrivateRoute exact path="/settings" component={Settings} />
              <PrivateRoute
                exact
                path="/settings/manage-bank"
                component={ManageBankAccounts}
              />
              <PrivateRoute path="/user-management">
                <React.Suspense fallback={<LoadingScreen variant="light" />}>
                  <UserManagementModule />
                </React.Suspense>
              </PrivateRoute>
              <PrivateRoute exact path="/invoices" component={Invoices} />
              <PrivateRoute
                exact
                path="/payment-confirmation/:payment_status"
                component={PaymentConfirmation}
              />
            </AppNavProvider>
            <Route component={NotFoundPage} />
          </Switch>
          <GlobalStyle />
        </AlertProvider>
      </MuiThemeProvider>
    </Router>
  );
}
