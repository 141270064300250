/**
 *
 * Asynchronously loads the component for MessageCenterChannels
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MessageCenterChannels = lazyLoad(
  () => import('./index'),
  module => module.MessageCenterChannels,
);
