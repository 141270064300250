/**
 *
 * Asynchronously loads the component for ReservationsFacilities
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ReservationsFacilities = lazyLoad(
  () => import('./index'),
  module => module.ReservationsFacilities,
);
