/**
 *
 * Asynchronously loads the component for ReservationsServices
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ReservationsServices = lazyLoad(
  () => import('./index'),
  module => module.ReservationsServices,
);
