import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from './styles';
import { BreadcrumbProps } from './types';

export const Breadcrumb = (props: BreadcrumbProps) => {
  const classes = useStyles();
  const { testID, links, rightLabel, currentLabel } = props;
  return (
    <Box>
      <div className={classes.topBorder} />
      <Box data-testid={testID} className={classes.wrapper}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {links &&
            links.map((link, index) => (
              <Link
                key={index}
                color="inherit"
                to={link.url}
                component={RouterLink}
              >
                {link.label}
              </Link>
            ))}
          <Typography color="textPrimary">{currentLabel}</Typography>
        </Breadcrumbs>
        {rightLabel && <Typography variant="body1">{rightLabel}</Typography>}
      </Box>
    </Box>
  );
};

Breadcrumb.defaultProps = {
  testID: 'breadcrumb',
};
