/**
 *
 * Asynchronously loads the component for Settings
 *
 */
import React from 'react';
import { lazyLoad } from 'utils/loadable';
import { LoadingScreen } from 'app/components/Loading';

export const Settings = lazyLoad(
  () => import('./index'),
  module => module.Settings,
  {
    fallback: <LoadingScreen variant="light" />,
  },
);
