/**
 *
 * Asynchronously loads the component for ProductFacilities
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProductFacilities = lazyLoad(
  () => import('./index'),
  module => module.ProductFacilities,
);
