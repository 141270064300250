import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, Profile } from './types';

// The initial state of the Common container
export const initialState: ContainerState = {
  auth: false,
  loading: false,
  profile: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) {},
    signOutAction(state) {
      state.auth = false;
      state.loading = false;
      state.profile = null;
    },
    checkAuthAction(state) {
      state.loading = true;
      state.profile = null;
    },
    checkAuthSuccessAction(state, action: PayloadAction<Profile>) {
      state.auth = true;
      state.loading = false;
      state.profile = action.payload;
    },
    checkAuthFailedAction(state) {
      state.auth = false;
      state.loading = false;
      state.profile = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = commonSlice;
