/**
 *
 * Asynchronously loads the component for PaymentConfirmation
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PaymentConfirmation = lazyLoad(
  () => import('./index'),
  module => module.PaymentConfirmation,
);
