import { createMuiTheme } from '@material-ui/core/styles';

// This is the theme settings for Devportal
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E1AC9D',
      dark: '#5B6E6E',
    },
    secondary: {
      main: '#5B6E6E',
    },
    info: {
      main: '#FFFFFF',
    },
    success: {
      main: '#17C261',
    },
    warning: {
      main: '#ECE2D7',
    },
    error: {
      main: '#FC7753',
    },
    text: {
      primary: '#000',
      secondary: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 14,
    htmlFontSize: 14,
    overline: {
      lineHeight: 1,
      fontSize: '11px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 500,
    },
    h1: {
      fontSize: '36px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '26px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#999999',
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '12px',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '15px',
        borderRadius: '50px',
        padding: '10.5px 15px',
        minWidth: '100px',
      },
      contained: {
        backgroundColor: '#F2F2F0',
        boxShadow: 'none',
        '&.Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
        },
      },
      containedPrimary: {
        color: '#fff',
        minHeight: '47px',
        '&:hover': {
          backgroundColor: '#a37871',
        },
      },
      containedSecondary: {
        color: '#fff',
      },
      outlined: {
        padding: '10.5px 15px',
      },
      outlinedPrimary: {
        color: '#fff',
        border: 'none',
        backgroundColor: '#17C261',
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: 'rgba(23, 194, 97, 0.8) !important',
            border: 'none',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(23, 194, 97, 0.8) !important',
          border: 'none',
        },
      },
      outlinedSecondary: {
        color: '#fff',
        border: 'none',
        backgroundColor: '#FC7753',
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: 'rgba(252, 119, 83, 0.8) !important',
            border: 'none',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(252, 119, 83, 0.8) !important',
          border: 'none',
        },
      },
      containedSizeSmall: {
        fontSize: '12px',
        padding: '6.5px 10px',
        minHeight: 0,
      },
      outlinedSizeSmall: {
        fontSize: '12px',
        padding: '6.5px 10px',
      },
    },
    MuiContainer: {
      root: {
        position: 'relative',
        zIndex: 1,
        paddingLeft: '30px',
        paddingRight: '30px',
      },
      maxWidthXs: {
        maxWidth: '490px !important',
      },
    },
    MuiPaper: {
      root: {
        padding: '30px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000',
      },
    },
    MuiInputLabel: {
      shrink: {
        opacity: 0.6,
      },
    },
    MuiSvgIcon: {
      root: {
        width: '0.7em',
        height: '0.7em',
        fontSize: '35px',
        color: '#CCCCCC',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #DDDDDD',
        },
        '&:after': {
          borderBottom: '2px solid #5B6E6E',
        },
      },
    },
    MuiListItem: {
      root: {
        padding: '15px 0',
        borderBottom: '1px solid #F1F0F2',
      },
      gutters: {
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: '16px',
      },
    },
    MuiTab: {
      root: {
        fontSize: '12px',
        minHeight: '50px',
      },
      textColorPrimary: {
        color: '#001A23',
        '&.Mui-selected': {
          // color: '#256EFF',
          color: '#00CCF2',
        },
      },
    },
    MuiTabs: {
      indicator: {
        // backgroundColor: '#256EFF',
        backgroundColor: '#00CCF2',
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '15px',
      },
    },
    MuiMenu: {
      paper: {
        padding: 0,
        borderRadius: '5px',
        marginTop: '35px',
        boxShadow: '0px 0px 3px rgba(39, 45, 59, 0.1)',
      },
      list: {
        padding: 0,
        '& .MuiMenuItem-root': {
          padding: '10px',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
    MuiDialog: {
      paperFullScreen: {
        background: '#5B6E6E',
        padding: 0,
      },
      paper: {
        '&.MuiPaper-rounded': {
          borderRadius: '25px !important',
          padding: '20px',
          minHeight: 0,
          width: '100%',
        },
        '& .MuiDialogTitle-root': {
          padding: '10px',
        },
        '& .MuiDialogContentText-root': {
          color: '#000',
          '& .MuiGrid-container': {
            marginBottom: '10px',
          },
        },
        '& .MuiDialogContent-root': {
          padding: '10px',
        },
      },
    },
    MuiPopover: {
      paper: {
        '& .MuiMenu-list': {
          maxHeight: '250px',
        },
      },
      root: {
        '&#menu-bank': {
          '& .MuiMenu-list .MuiMenuItem-root': {
            whiteSpace: 'normal',
          },
          '& .MuiPaper-root.MuiMenu-paper': {
            maxWidth: 'min-content',
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '50px',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
      },
    },
    MuiInputAdornment: {
      root: {
        '& .MuiTypography-colorTextSecondary': {
          color: '#000',
        },
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 2000,
        '& .MuiAlert-filledSuccess': {
          alignItems: 'center',
        },
        '& .MuiSvgIcon-root': {
          color: '#fff',
          fontSize: '35px',
        },
      },
    },
    MuiChip: {
      root: {
        fontSize: '11px',
        textTransform: 'capitalize',
        color: '#fff',
        '&.pending': {
          backgroundColor: '#FED385',
        },
        '&.approved': {
          backgroundColor: '#00CCF2',
        },
        '&.rejected, &.canceled': {
          backgroundColor: '#FD8369',
        },
        '&.completed': {
          backgroundColor: '#A1A395',
        },
      },
    },
  },
});

export default theme;
