/**
 *
 * Asynchronously loads the component for ManageProperty
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ManageProperty = lazyLoad(
  () => import('./index'),
  module => module.ManageProperty,
);
