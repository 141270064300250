import React, { useState, useContext, createContext } from 'react';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

type AlertType = 'success' | 'info' | 'warning' | 'error';

interface Props {
  children: React.ReactNode;
}

const AlertContext = createContext({
  notify: (message: string, color: AlertType) => {},
});

export function useAlert() {
  return useContext(AlertContext);
}

const SnackAlert = props => {
  return <Alert elevation={6} variant="filled" {...props} />;
};

export function AlertProvider(props: Props) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<AlertType>('error');

  const notify = (message: string, type: AlertType) => {
    setMessage(message);
    setType(type);
    setShow(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  return (
    <AlertContext.Provider value={{ notify }}>
      {props.children}
      <Snackbar
        open={show}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackAlert onClose={handleClose} severity={type}>
          {message}
        </SnackAlert>
      </Snackbar>
    </AlertContext.Provider>
  );
}
