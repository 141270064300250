import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-size: 14px;
    background: #fafafa;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Poppins', Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .innerContainer {
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .MuiPickersModal-dialogRoot {
    padding: 0 !important;
    border-radius: 10px !important;
    min-height: 0 !important;
  }

  h6.MuiPickersToolbarText-toolbarTxt {
    font-size: 16px;
    color: #fff;
  }

  h4.MuiPickersToolbarText-toolbarTxt {
    font-size: 22px;
    color: #fff;
  }

  .MuiPickersYear-root {
    font-size: 14px !important;
  }

  .MuiPickersYear-yearSelected {
    font-size: 20px !important;
  }

  .MuiPickersMonth-root.MuiPickersMonth-monthSelected {
    font-size: 20px !important;
  }

  .MuiPickersMonth-root {
    text-transform: unset !important;
    font-size: 14px !important;
  }

  .MuiPickersMonth-monthDisabled {
    color: rgba(0, 0, 0, 0.2) !important;
  }

  .loadingBtn {
    max-width: 26px !important;
    margin: 0 !important;
  }
`;
