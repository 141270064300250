import firebase from 'firebase/app';
import 'firebase/auth';
import { appConfig } from '../config/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(appConfig);
}

const auth = firebase.auth();

const signInWithCustomToken = token =>
  auth
    .signInWithCustomToken(token)
    .then(user => user)
    .catch(error => {
      throw error;
    });

const getIdToken = user =>
  user
    .getIdToken()
    .then(token => token)
    .catch(error => {
      throw error;
    });

const signOut = () =>
  auth
    .signOut()
    .then(data => data)
    .catch(error => {
      throw error;
    });

const getUser = () =>
  new Promise(resolve => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    });
  });

const reauthenticateWithCredential = (user, credentials) =>
  user.reauthenticateWithCredential(credentials).catch(error => {
    throw error;
  });

const updatePassword = (user, newPassword) =>
  user
    .updatePassword(newPassword)
    .then(result => result)
    .catch(error => {
      throw error;
    });
export {
  auth,
  getIdToken,
  signOut,
  signInWithCustomToken,
  getUser,
  firebase,
  reauthenticateWithCredential,
  updatePassword,
};
