/**
 *
 * Loading
 *
 */
import React, { memo, useEffect } from 'react';
import classNames from 'classnames';

import Box from '@material-ui/core/List';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { LinearProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import loadIcon from '../../../images/loading.svg';
import Logo from '../../../images/kubo_logo.png';
import LogoDark from '../../../images/kubo_logo_dark.png';
import { useStyles } from './styles';

interface Props {}

interface LoadingScreenProps {
  variant: string;
}

export const Loading = memo((props: Props) => {
  return <div></div>;
});

export const LoadingIcon = memo((props: Props) => {
  return <img src={loadIcon} alt="loading" className="loadingBtn" />;
});

export const LoadingAnnouncement = memo((props: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.announcementBoxLoading}>
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={200} height={30} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" width={100} />
    </Box>
  );
});

export const LoadingList = memo((props: Props) => {
  const classes = useStyles();

  const loadingComponent = (
    <div>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </div>
  );
  return (
    <List className={classes.listLoading}>
      <ListItem>{loadingComponent}</ListItem>
      <ListItem>{loadingComponent}</ListItem>
      <ListItem>{loadingComponent}</ListItem>
      <ListItem>{loadingComponent}</ListItem>
      <ListItem>{loadingComponent}</ListItem>
    </List>
  );
});

export const LoadingScreen = memo((props: LoadingScreenProps) => {
  const classes = useStyles();
  const variantColor = props.variant;

  // ScrollEvent needed for navigation
  const [variant, setVariant] = React.useState(false);
  const handleVariant = () => {
    if (variantColor === 'light') {
      setVariant(true);
    } else {
      setVariant(false);
    }
  };
  useEffect(() => {
    handleVariant();
  });

  return (
    <div className={classNames(classes.loaderContainer, { light: variant })}>
      <img
        src={variant ? LogoDark : Logo}
        alt="Logo"
        className={classes.loaderImage}
      />
      <LinearProgress
        className={classNames(classes.loaderProgress, { lightLoader: variant })}
      />
    </div>
  );
});

export const LoadingTable = memo((props: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.tableLoading}>
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
    </Box>
  );
});
