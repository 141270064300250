/**
 *
 * Asynchronously loads the component for SecurityApprovals
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SecurityApprovals = lazyLoad(
  () => import('./index'),
  module => module.SecurityApprovals,
);
