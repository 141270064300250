import { Cookies } from 'react-cookie';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getIdToken, getUser, signOut } from 'utils/firebase';
import history from 'utils/history';
import { Decode, UserProfile } from 'utils/jwt';
import { actions } from './slice';

export function* checkAuth() {
  const cookies = new Cookies();
  try {
    const user = yield call(getUser);
    if (user) {
      const idToken = yield call(getIdToken, user);
      const info: any = UserProfile(Decode(idToken));
      cookies.set('user_profile', info, { path: '/' });
      yield put(actions.checkAuthSuccessAction(info));
    } else {
      cookies.remove('user_profile', { path: '/' });
      yield put(actions.checkAuthFailedAction());
    }
  } catch (error) {
    console.log(error);
    cookies.remove('user_profile', { path: '/' });
    yield put(actions.checkAuthFailedAction());
  }
}

export function* signout() {
  try {
    console.log('Logging out...');
    const cookies = new Cookies();
    cookies.remove('user_profile', { path: '/' });
    cookies.remove('id_token', { path: '/' });
    yield call(signOut);
    yield put(actions.checkAuthFailedAction());
  } catch (error) {
    console.log('Logout error: ', error);
    history.push('/');
  }
}
export function* commonSaga() {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield takeLatest(actions.checkAuthAction, checkAuth);
  yield takeLatest(actions.signOutAction, signout);
}
