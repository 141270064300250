/**
 *
 * Asynchronously loads the component for ProductService
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProductService = lazyLoad(
  () => import('./index'),
  module => module.ProductService,
);
