/**
 *
 * Asynchronously loads the component for ReservationsParking
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ReservationsParking = lazyLoad(
  () => import('./index'),
  module => module.ReservationsParking,
);
