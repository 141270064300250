/**
 *
 * Asynchronously loads the component for BillingAddTransaction
 *
 */
import React from 'react';
import { lazyLoad } from 'utils/loadable';
import { LoadingScreen } from '../../components/Loading';

export const BillingAddTransaction = lazyLoad(
  () => import('./index'),
  module => module.BillingAddTransaction,
  {
    fallback: <LoadingScreen variant="light" />,
  },
);
