import jwt_decode from 'jwt-decode';

/**
 * Decode a jwt token.
 * @param {string} token
 * @return {object}
 */
export function Decode(token: string): object {
  return jwt_decode(token);
}

/**
 * Decode a jwt token header.
 * @param {string} token
 * @return {object}
 */
export function DecodeHeader(token: string): object {
  return jwt_decode(token, { header: true });
}

export function UserProfile(user: any): object {
  return {
    uid: user.user_id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    roles: user.roles,
    account: user.account,
  };
}
