/**
 *
 * Asynchronously loads the component for SecurityApprovalsRejected
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SecurityApprovalsRejected = lazyLoad(
  () => import('./index'),
  module => module.SecurityApprovalsRejected,
);
