import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '40px',
      '& a, li, p': {
        color: '#7C7C7C',
      },
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    topBorder: {
      borderTop: '1px solid #E5E5E5',
    },
  }),
);
