import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    announcementBoxLoading: {
      background: '#f4f4f4',
      padding: '20px 30px',
      width: '100%',
      marginBottom: '20px',
    },
    listLoading: {
      padding: '10px 0',
      '& div': {
        width: '100%',
      },
    },
    loaderContainer: {
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'fixed',
      width: '100%',
      left: 0,
      top: 0,
      zIndex: 1400,
      background: '#5B6E6E',
      textAlign: 'center',
      '&.light': {
        background: 'rgba(255,255,255,0.8)',
      },
    },
    loaderImage: {
      maxWidth: '100px',
      margin: '20px auto',
    },
    loaderProgress: {
      width: '100%',
      maxWidth: '180px',
      backgroundColor: 'rgba(255,255,255,0.3)',
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#fff',
      },
      '&.lightLoader': {
        backgroundColor: 'rgba(0,0,0,0.3)',
      },
      '&.lightLoader .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#000',
      },
    },
    tableLoading: {
      '& span': {
        padding: '15px',
        margin: '0 0 15px',
        borderRadius: '5px',
      },
    },
  }),
);
