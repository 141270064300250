import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 270;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .appBarContainer': {
        [theme.breakpoints.up('md')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
      },
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      padding: 0,
      boxShadow: '2px 2px 6px 0px rgba(39, 45, 59, 0.2)',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      background: '#5b6e6e',
      padding: 0,
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4),
      maxWidth: '1140px',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 20px',
      },
    },
    userInitials: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '20px',
      fontWeight: 600,
      border: '3px solid #f4f4f4',
    },
    drawerContainer: {
      height: '100%',
    },
    profileDetails: {
      padding: '0 25px',
      position: 'relative',
      width: '100%',
      maxWidth: '300px',
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      '& .MuiAvatar-root': {
        width: '50px',
        height: '50px',
        marginRight: '10px',
        borderRadius: '50%',
      },
      '& h2': {
        marginBottom: '0',
        lineHeight: 1.3,
        fontSize: '16px',
      },
      '& .MuiTypography-subtitle1': {
        fontSize: '10px',
      },
    },
    navigationBox: {
      minHeight: '400px',
      position: 'relative',
      height: 'calc(100% - 190px)',
      marginTop: '20px',
    },
    mainNavigation: {
      width: '100%',
      position: 'relative',
      margin: '10px 0',
      '& .MuiListItem-root': {
        padding: '10px 5px 10px 25px',
        borderBottom: 'none',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '35px',
      },
      '& .MuiListItemIcon-root img': {
        width: '24px',
      },
      '& .MuiListItem-root:last-child img': {
        width: '20px',
      },
      '& .MuiListItemText-primary': {
        fontSize: '16px',
        color: '#fff',
      },
    },
    logoutNavigation: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      margin: '10px 0',
      '& .MuiListItem-root': {
        padding: '10px 30px',
        borderBottom: 'none',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '44px',
      },
      '& .MuiListItemIcon-root img': {
        width: '24px',
      },
      '& .MuiListItem-root:last-child img': {
        width: '19px',
      },
      '& .MuiListItemText-primary': {
        fontSize: '16px',
        color: '#fff',
      },
      '@media (max-height: 860px)': {
        position: 'relative',
      },
    },
    logoContainer: {
      width: '100%',
      padding: '30px',
      textAlign: 'center',
      '& img': {
        maxWidth: '160px',
      },
    },
    switchBtn: {
      margin: '0 0 0 auto',
      fontSize: '12px',
      padding: '7px 20px',
      minHeight: 0,
    },
    propertyName: {
      margin: '0 auto 20px',
      padding: '15px 0',
      textAlign: 'center',
      borderTop: '1px solid rgba(255,255,255,0.3)',
      borderBottom: '1px solid rgba(255,255,255,0.3)',
      color: '#fff',
      textTransform: 'uppercase',
      maxWidth: '85%',
      '& .MuiTypography-h3': {
        fontSize: '16px',
      },
    },
    active: {
      backgroundColor: 'rgba(0,0,0,0.22)',
    },
    switchIcon: {
      color: '#FFFFFF',
    },
  }),
);
