/**
 *
 * Asynchronously loads the component for ForgotPassword
 *
 */
import React from 'react';
import { lazyLoad } from 'utils/loadable';
import { LoadingScreen } from '../../components/Loading';

export const ForgotPassword = lazyLoad(
  () => import('./index'),
  module => module.ForgotPassword,
  {
    fallback: <LoadingScreen variant="light" />,
  },
);
