import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';
import Logout from 'images/icons/logout.svg';
import Switch from 'images/icons/switch.svg';
import KuboLogo from 'images/kubo_logo_horizontal.png';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import navigation from './data/navigation';
import { useStyles } from './styles';
import { DrawerProps } from './types';

const avatarName = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

export const Drawer = (props: DrawerProps) => {
  const classes = useStyles();
  const { logout, userProfile } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleMobileDrawerToggle = () => {
    if (window.innerWidth < 960) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <div className={classes.drawerContainer}>
      <Box className={classes.logoContainer}>
        <Link component={RouterLink} to="/dashboard">
          <img src={KuboLogo} alt="Logo" />
        </Link>
      </Box>
      {/* <Box className={classes.propertyName}>
          <Typography variant="h3">Property Name</Typography>
        </Box> */}
      <Box className={classes.profileDetails}>
        {userProfile && (
          <Avatar className={classes.userInitials}>
            {avatarName(userProfile.first_name, userProfile.last_name)}
          </Avatar>
        )}
        <Typography variant="h2" color="textSecondary">
          {userProfile && `${userProfile.first_name} ${userProfile.last_name}`}
        </Typography>
      </Box>
      <div className={classes.navigationBox}>
        <List className={classes.mainNavigation}>
          {navigation.map((item, index) => (
            <ListItem
              button
              component={RouterLink}
              to={item.link}
              key={index}
              onClick={handleMobileDrawerToggle}
              className={classnames({
                [classes.active]:
                  item.activeRoute.includes(window.location.pathname) ||
                  window.location.pathname.includes(item.link),
              })}
            >
              <ListItemIcon>
                <img src={item.url} alt={item.alt} />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
        <List className={classes.logoutNavigation}>
          <ListItem button component={RouterLink} to="/manage-property">
            <ListItemIcon>
              <img src={Switch} alt="switch" />
            </ListItemIcon>
            <ListItemText primary="Switch Property" />
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <img src={Logout} alt="logout" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};
