import classnames from 'classnames';
import React, { createContext, useContext, useState } from 'react';
import { useStyles } from './styles';
import { AppNavProps } from './types';

import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Breadcrumb } from 'app/components/Breadcrumb/Breadcrumb';
import { BreadcrumbProps } from 'app/components/Breadcrumb/types';
import { Drawer as DrawerContent } from './Drawer';

const AppNavContext = createContext({
  setBreadcrumbNav: (breadcrumb: BreadcrumbProps | null) => {},
  setNavTitle: (title: string) => {},
  clear: () => {},
});

export const useAppNav = () => useContext(AppNavContext);

export function AppNavProvider(props: AppNavProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { children, logout, userProfile } = props;
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbProps | null>(
    null,
  );
  const [title, setTitle] = useState<string>('');
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const setBreadcrumbNav = (breadcrumb: BreadcrumbProps | null) => {
    setBreadcrumbData(breadcrumb);
  };

  const setNavTitle = (title: string) => {
    setTitle(title);
  };

  const clear = () => {
    setBreadcrumbData(null);
    setTitle('');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppNavContext.Provider value={{ setBreadcrumbNav, setNavTitle, clear }}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classnames(classes.appBar, 'appBarContainer')}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {title && (
              <Typography
                style={{ fontSize: '16px', color: '#000', flexGrow: 1 }}
              >
                {title}
              </Typography>
            )}
          </Toolbar>
          {breadcrumbData && (
            <Breadcrumb
              links={breadcrumbData.links}
              currentLabel={breadcrumbData.currentLabel}
              rightLabel={breadcrumbData.rightLabel}
            />
          )}
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <DrawerContent logout={logout} userProfile={userProfile} />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <DrawerContent logout={logout} userProfile={userProfile} />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </AppNavContext.Provider>
  );
}

AppNavProvider.defaultProps = {
  testID: 'AppNav',
  userProfile: null,
};
