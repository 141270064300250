/**
 *
 * Asynchronously loads the component for BookADemo
 *
 */

import { lazyLoad } from 'utils/loadable';

export const BookADemo = lazyLoad(
  () => import('./index'),
  module => module.BookADemo,
);
